@use "@/_styles/theme" as *;

.root
{
	@include flex;
	@include align(center, center);
	height: 100vh;

	> img
	{
		width: 300px;
		height: auto;

		@media (prefers-color-scheme: dark)
		{
			&.light
			{
				display: none;
			}
		}

		@media (prefers-color-scheme: light)
		{
			&.dark
			{
				display: none;
			}
		}
	}
}
